import React, { useEffect, useRef } from "react";
import gsap from "gsap";

import image_profile_2 from "../images/profile.jpg";
import ScrollTrigger from "gsap/ScrollTrigger";
import useOnScreen from "../hooks/useOnScreen";
import { useLoading } from "../hooks/app";
import {
  experience,
  personalSkills,
  professionalSkills,
} from "../data/experience";

gsap.registerPlugin(ScrollTrigger);

const Resume = () => {
  const personalSkillsRef = useRef();
  const personalSkillOnScreen = useOnScreen(personalSkillsRef);
  const professionalSkillOnScreen = useOnScreen(personalSkillsRef);

  const { isLoading, setLoading } = useLoading();

  useEffect(() => {
    if (isLoading) {
      setLoading(false);
    }
  }, [isLoading, setLoading]);

  useEffect(() => {
    setLoading(false);
  }, [personalSkillOnScreen, setLoading]);
  return (
    <>
      {/* // <!--  Card - Resume --> */}
      <div className="card-inner active" id="resume-card">
        <div className="row card-container">
          {/* <!-- Card Wrap --> */}
          <div
            className="card-wrap col col-m-12 col-t-12 col-d-8 col-d-lg-6"
            data-simplebar
          >
            {/* <!-- Resume Image --> */}
            <div
              className="card-image col col-m-12 col-t-12 col-d-4 col-d-lg-6"
              style={{ backgroundImage: `url(${image_profile_2})` }}
            ></div>

            {/* <!-- Inner Top --> */}
            <div className="content inner-top">
              <div className="row">
                <div className=" col col-m-12 col-t-12 col-d-12 col-d-lg-12">
                  <div className="title-bg">Resume</div>
                </div>
              </div>
            </div>

            {/* <!-- Resume --> */}
            <div className="content resume">
              <div className="row">
                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                  {/* <!-- title --> */}
                  <div className="title">
                    <span>My</span> Experience
                  </div>

                  {/* <!-- resume items --> */}
                  <div className="resume-items card-box">
                    {experience.map((item) => (
                      /* <!-- resume item --> */
                      <div key={item.company} className="resume-item">
                        <div className="name">{item.name}</div>
                        <div className="date">
                          {item.date} <span>|</span> {item.company}
                        </div>
                        <p>{item.desc}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                  {/* <!-- title --> */}
                  <div className="title">
                    <span>My</span> Education
                  </div>

                  {/* <!-- resume items --> */}
                  <div className="resume-items card-box">
                    {/* <!-- resume item --> */}
                    <div className="resume-item">
                      <div className="name">University of the Punjab</div>
                      <div className="date">
                        2016-2020 <span>|</span> Lahore, Pakistan
                      </div>
                      <p>Bachelor's Degree in Information Technology</p>
                    </div>

                    {/* <!-- resume item --> */}
                    <div className="resume-item">
                      <div className="name">Government College University</div>
                      <div className="date">
                        2014-2016 <span>|</span> Lahore, Pakistan
                      </div>
                      <p>
                        Intermediate's Degree in Information and Computer
                        Sciences
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Skills */}
            <div className="content skills">
              <div className="row">
                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                  {/* <!-- title --> */}
                  <div className="title">
                    <span>Personal</span> Skills
                  </div>

                  {/* <!-- skills --> */}
                  <div className="skills-list card-box" ref={personalSkillsRef}>
                    <ul>
                      {personalSkills.map(({ title, width }, index) => {
                        return (
                          <li key={index}>
                            <div className="name">{title}</div>
                            <div className="progress">
                              <div
                                className="percentage"
                                style={{
                                  width: personalSkillOnScreen ? width : 0,
                                }}
                              ></div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                  {/* <!-- title --> */}
                  <div className="title">
                    <span>Professional</span> Skills
                  </div>

                  {/* <!-- skills --> */}
                  <div className="skills-list card-box">
                    <ul>
                      {professionalSkills.map(({ title, width }, index) => {
                        return (
                          <li key={index}>
                            <div className="name">{title}</div>
                            <div className="progress">
                              <div
                                className="percentage"
                                style={{
                                  width: professionalSkillOnScreen ? width : 0,
                                }}
                              ></div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resume;
