export const experience = [
  {
    name: "Full Stack Web Engineer",
    date: "2022-Present",
    desc: "Successfully delivered high-quality projects on Upwork, demonstrating expertise, reliability, and a commitment to client satisfaction.",
    company: "Upwork",
  },
  {
    name: "Senior Frontend Developer",
    date: "2023-Present",
    desc: "Developed and maintained the frontend of a personalized merchandise platform using Next.js and Material UI.",
    company: "Merch.com",
  },
  {
    name: "Full Stack Software Engineer",
    date: "2020-2022",
    desc: "Developed and maintained both frontend and backend components, ensuring a seamless integration between the two.",
    company: "nexByte",
  },
];

export const personalSkills = [
  {
    title: "Communication",
    width: "100%",
  },
  {
    title: "Team Work",
    width: "100%",
  },
  {
    title: "Leadership",
    width: "90%",
  },
  {
    title: "Language",
    width: "90%",
  },
];

export const professionalSkills = [
  {
    title: "Git",
    width: "100%",
  },
  {
    title: "React.js",
    width: "100%",
  },
  {
    title: "Next.js",
    width: "100%",
  },
  {
    title: "Python",
    width: "100%",
  },
  {
    title: "Django",
    width: "100%",
  },
  {
    title: "Wagtail CMS",
    width: "90%",
  },
  {
    title: "JavaScript",
    width: "100%",
  },
  {
    title: "TypeScript",
    width: "100%",
  },
  {
    title: "Material UI",
    width: "100%",
  },
  {
    title: "Bootstrap",
    width: "100%",
  },
  {
    title: "Graphql",
    width: "90%",
  },
  {
    title: "Shopify",
    width: "90%",
  },
  {
    title: "Wordpress",
    width: "90%",
  },
];
