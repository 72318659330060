export const contactInfoData = [
  {
    icon: "la la-map-marker",
    name: "address",
    text: "Lahore, Punjab, Pakistan.",
  },
  {
    icon: "la la-at",
    name: "email",
    text: "anassheikh57@gmail.com",
  },
  {
    icon: "la la-phone",
    name: "phone",
    text: "+92 323 0496310",
  },
  {
    icon: "la la-check-square",
    name: "freelence",
    text: "available",
  },
];
