import React from "react";
import { MdClose } from "react-icons/md";
import styled from "styled-components";

const PopupCard = (props) => {

  const { item } = props;

  return (
    <PopupStyled>
      <div
        id="popup-1"
        className="popup-box mfp-fade mfp-show"
        style={{
          background: "#1d1e21",
          padding: "0 20px",
          width: "635px",
          height: "100%",
        }}
      >
        <div className="content">
          <div className="image">
            <img style={{ objectFit: 'none' }} width={'100%'} height={650} src={item.image_url} alt="patrick" />
          </div>
          <div className="desc">
            {item.detail}
          </div>
        </div>
      </div>
      <button className="btn-close" onClick={() => props.setPopupValue(null)}>
        <MdClose />
      </button>
    </PopupStyled>
  );
};

export default PopupCard;

const PopupStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding-top: 50px;

  // ::-webkit-scrollbar {
  //   display: none;
  // }

  // .popup-box {
  //   overflow-y: scroll;
  // }

  .btn-close {
    height: 20px;
    width: 20px;
    padding: 0 10px;

    svg {
      font-size: 40px;
    }
  }

  .blog-content {
    font-style: oblique;
  }

  .list-style {
    list-style: circle inside;
    margin-top: 10px;
  }
`;
