const socials = [
  {
    url: "https://www.linkedin.com/in/anasraza57/",
    icon: 'icon la la-linkedin',
    name: 'linkedIn'
  },
  {
    url: "https://github.com/anasraza57/",
    icon: 'icon la la-github',
    name: 'github'
  },
];
export default socials;
