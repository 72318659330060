const menu = [
  {
    to: "#",
    icon: 'icon la la-home',
    title: 'Home'
  },
  {
    to: "#about",
    icon: 'icon la la-user',
    title: 'about'
  },
  {
    to: "#resume",
    icon: 'icon la la-file-text',
    title: 'resume'
  },
  {
    to: "#portfolio",
    icon: 'icon la la-eye',
    title: 'portfolio'
  },
  {
    to: "#contact",
    icon: 'icon la la-envelope',
    title: 'contact'
  },
];
export default menu;
