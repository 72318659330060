export default function Overlay() {

	return (
		<div className="intro__overlay">
			<div className="intro__overlay-line">&nbsp;</div>
			<div className="intro__overlay-line">&nbsp;</div>
			<div className="intro__overlay-line">&nbsp;</div>
			<div className="intro__overlay-line">&nbsp;</div>
		</div>
	);
}
