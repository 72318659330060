import works_img_1 from "./images/works/merch.jpg";
import works_img_2 from "./images/works/trackhero.jpg";
import works_img_3 from "./images/works/santa.jpg";
import works_img_4 from "./images/works/elegante.jpg";
import works_img_5 from "./images/works/caffe.jpg";
import works_img_6 from "./images/works/mayfoi.jpg";
import works_img_7 from "./images/works/energy.jpg";

export const WORK_DATA = [
  {
    id: 1,
    image_url: works_img_1,
    title: "Merch.com",
    icon: "la la-search",
    desc: "Front End Development",
    detail: (
      <div className="post-box">
        <h1>Merch.com</h1>
        <div className="blog-detail">Front End Development</div>
        <div className="blog-content">
          <p>
            Introducing our innovative Project Management Platform, a premier
            solution catering to personalized merchandise requests. Seamlessly
            create custom merchandise by simply submitting your preferred
            artwork for each product and effortlessly placing orders. Our
            platform empowers you with efficient inventory management and
            streamlined shipment handling capabilities.
          </p>
          <p>
            Our technology stack boasts Next.js for dynamic front-end
            experiences, complemented by Node.js for robust backend
            functionality while harnessing the power of GraphQL as the query
            language for our APIs (application programming interfaces).
            Additionally, we employ Azure DevOps to expertly manage repositories
            for both the Client and Admin sites, as well as to orchestrate the
            creation of pipelines and facilitate seamless deployments.
          </p>
          <p>
            With our professional-grade Project Management Platform, your custom
            merchandise needs are in capable hands, ensuring a seamless and
            rewarding experience for your business.
          </p>
        </div>
        <strong>Technology:</strong>
        <ul className="list-style">
          <li>NextJS</li>
          <li>NodeJS</li>
          <li>GraphQL</li>
          <li>MongoDB</li>
          <li>Azure DevOps</li>
          <li>Material UI</li>
        </ul>
        <a
          href="https://merch.com/"
          className="button"
          target="_blank"
          rel="noreferrer"
        >
          <span className="text">View Project</span>
          <span className="arrow"></span>
        </a>
      </div>
    ),
  },
  {
    id: 2,
    image_url: works_img_2,
    title: "TrackHero",
    icon: "la la-search",
    desc: "Full Stack Development",
    detail: (
      <div className="post-box">
        <h1>TrackHero</h1>
        <div className="blog-detail">Full Stack Development</div>
        <div className="blog-content">
          <p>
            TrackHero is a cloud-based platform designed to provide full
            visibility into your fleet and easy management of your experiential
            events and customers. They built their B2B SaaS solution with a
            vision to enable smoother workflows that make the job of dealership
            businesses a delight. It offers a centralized feature set for
            managing Events, Rentals, Fleets, and Customers with curated
            workflows catering to the needs of multiple actors who run these
            auto shows.
          </p>
        </div>
        <strong>Technology:</strong>
        <ul className="list-style">
          <li>Python</li>
          <li>Django</li>
          <li>React</li>
          <li>LESS</li>
          <li>Bootstrap</li>
          <li>Stripe</li>
          <li>Twilio</li>
          <li>Zoho</li>
          <li>Salesforce</li>
        </ul>
        <a
          href="https://trackhero.com/"
          className="button"
          target="_blank"
          rel="noreferrer"
        >
          <span className="text">View Project</span>
          <span className="arrow"></span>
        </a>
      </div>
    ),
  },
  {
    id: 3,
    image_url: works_img_3,
    title: "Santa Videos",
    icon: "la la-search",
    desc: "Full Stack Development",
    detail: (
      <div className="post-box">
        <h1>Santa Videos</h1>
        <div className="blog-detail">Full Stack Development</div>
        <div className="blog-content">
          <p>
            Within this project, I had the opportunity to develop a unique and
            heartwarming ordering system. This system was designed to empower
            parents with the ability to curate personalized, memorable videos,
            meant to be cherished by their children as a thoughtful and
            enchanting Christmas gift. The platform seamlessly combined
            creativity and technology, allowing parents to infuse their love and
            warmth into every detail of the videos, creating a truly magical
            holiday surprise.
          </p>
        </div>
        <strong>Technology:</strong>
        <ul className="list-style">
          <li>React</li>
          <li>Material UI</li>
          <li>JavaScript</li>
          <li>Node</li>
          <li>AWS</li>
        </ul>
        <a
          href="https://santavideos.com/"
          className="button"
          target="_blank"
          rel="noreferrer"
        >
          <span className="text">View Project</span>
          <span className="arrow"></span>
        </a>
      </div>
    ),
  },
  {
    id: 4,
    image_url: works_img_4,
    title: "Elegante Hair Studio",
    icon: "la la-search",
    desc: "Front End Development",
    detail: (
      <div className="post-box">
        <h1>Elegante Hair Studio</h1>
        <div className="blog-detail">Front End Development</div>
        <div className="blog-content">
          <p>
            This Project has multiple parts, I have worked on Saloon Manager
            Widget. It is a standalone React app that is integrated into
            different websites using iFrame. This app is used to book saloon
            appointments with available employees. You can also see the prices,
            business hours, customer services pictures, buy gift cards and add
            reviews.
          </p>
        </div>
        <strong>Technology:</strong>
        <ul className="list-style">
          <li>React</li>
          <li>Typescript</li>
        </ul>
        <a
          href="https://elegantehairstudio.com/"
          className="button"
          target="_blank"
          rel="noreferrer"
        >
          <span className="text">View Project</span>
          <span className="arrow"></span>
        </a>
      </div>
    ),
  },
  {
    id: 5,
    image_url: works_img_5,
    title: "Emen Caffe",
    icon: "la la-search",
    desc: "Wordpress",
    detail: (
      <div className="post-box">
        <h1>Emen Caffe</h1>
        <div className="blog-detail">Wordpress</div>
        <div className="blog-content">
          <p>
            This project involved the creation of a dynamic and engaging website
            using WordPress, specifically tailored for a renowned cafe that has
            earned its place as a beloved destination for dessert enthusiasts
            and coffee aficionados alike.
          </p>
        </div>
        <a
          href="https://www.emencaffe.com/"
          className="button"
          target="_blank"
          rel="noreferrer"
        >
          <span className="text">View Project</span>
          <span className="arrow"></span>
        </a>
      </div>
    ),
  },
  {
    id: 6,
    image_url: works_img_6,
    title: "MayFoi",
    icon: "la la-search",
    desc: "Shopify",
    detail: (
      <div className="post-box">
        <h1>MayFoi</h1>
        <div className="blog-detail">Shopify</div>
        <div className="blog-content">
          <p>
            Undertaking this endeavor, I had the privilege of crafting a
            sophisticated online presence using Shopify, meticulously tailored
            to suit the essence of a distinguished Canadian brand specializing
            in Luxury Modest Attire. The outcome not only mirrored the brand's
            commitment to elegance and refinement but also provided a seamless
            e-commerce experience for those seeking the perfect fusion of style
            and modesty.
          </p>
        </div>
        <a
          href="https://mayfoi.com/"
          className="button"
          target="_blank"
          rel="noreferrer"
        >
          <span className="text">View Project</span>
          <span className="arrow"></span>
        </a>
      </div>
    ),
  },
  {
    id: 7,
    image_url: works_img_7,
    title: "Community Energy Toolkit (COMET)",
    icon: "la la-search",
    desc: "Full Stack Development",
    detail: (
      <div className="post-box">
        <h1>Community Energy Toolkit (COMET)</h1>
        <div className="blog-detail">Full Stack Development</div>
        <div className="blog-content">
          <p>
            Community Energy Toolkit (COMET), a role-playing software tool built
            around a representation of a mini-grid system, intended to be used
            as an educational and collaborative planning tool in designing a
            community-sized mini-grid system. The tool is designed to be used
            within a process that explores mini-grid planning and operational
            decisions.
          </p>
        </div>
        <strong>Technology:</strong>
        <ul className="list-style">
          <li>Python</li>
          <li>Django</li>
          <li>React</li>
          <li>SCSS</li>
          <li>Bootstrap</li>
          <li>Typescript</li>
          <li>Echarts</li>
          <li>Apex Charts</li>
        </ul>
      </div>
    ),
  },
];
