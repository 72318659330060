import React, { useEffect } from "react";
import ProfileImg from "../images/about.JPG";
import { useLoading } from "../hooks/app";
import { BiDumbbell } from "react-icons/bi";
import { SiUpwork } from "react-icons/si";
import { RiLinkedinFill, RiGithubLine } from "react-icons/ri";

const About = () => {
  const serviceItemData = [
    {
      icon_url: "la la-html5",
      title: "Web Development",
      text: "Modern and mobile-ready website that will help of your marketing.",
    },
  ];

  const FactsData = [
    {
      icon_url: "la la-lightbulb-o",
      title: "10+ Projects Completed",
    },
    {
      icon_url: "la la-code",
      title: "100 000 Lines of Code",
    },
    {
      icon: <BiDumbbell/>,
      title: "Gym Fanatic",
    },
    {
      icon_url: "la la-spotify",
      title: "Melophile",
    },
  ];

  const { isLoading, setLoading } = useLoading();

  const onCVButtonClick = () => {
    // using JavaScript method to get PDF file
    fetch('AnasRaza.pdf').then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'AnasRaza.pdf';
        alink.click();
      })
    })
  }

  useEffect(() => {
    if (isLoading) {
      setLoading(false);
    }
  }, [isLoading, setLoading]);

  return (
    <>
      {/* Card - About */}
      <div
        className="card-inner active"
        id="about-card"
      >
        <div className="row card-container">
          {/* Card Wrap */}
          <div className="card-wrap col col-m-12 col-t-12 col-d-8 col-d-lg-6">
            {/* About Image  */}
            <div
              className="card-image col col-m-12 col-t-12 col-d-4 col-d-lg-6"
              style={{ backgroundImage: `url(${ProfileImg})` }}
            ></div>
            {/* Inner Top */}
            <div className="content inner-top">
              <div className="row">
                <div className="col col-m-12 col-t-12 col-d-12 col-d-lg-12">
                  <div className="title-bg">About Me</div>
                  <div className="text">
                    <p>
                      <strong>Hello, I’m Anas</strong>, full stack web-developer based on Pakistan. <br/>
                      As an accomplished software developer with 4 years of<br/>
                      hands-on experience across various programming<br/>
                      languages, I am resolute in my commitment to<br/>
                      delivering exceptional results and contributing<br/>
                      significant value to any organization I serve.<br/>
                      Also I am good at{" "}
                      <strong>
                        python, django, reactJs,<br/>
                        nextJs, NodeJs, javascript, typescript,<br/>
                        shopify, Wordpress, Material UI,<br/>
                        HTML/CSS, and bootstrap.
                      </strong>
                    </p>
                  </div>
                  <div className="circle-bts">
                    <button onClick={onCVButtonClick}>
                      <span>
                        <i className="icon la la-download"></i>Download CV
                      </span>
                    </button>
                    <a href="https://github.com/anasraza57/" target="_blank" rel="noreferrer">
                      <i className="icon" style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        height: '100%'
                      }}>
                        <RiGithubLine/>
                      </i>
                    </a>
                    <a href="https://www.linkedin.com/in/anasraza57/" target="_blank" rel="noreferrer">
                      <i className="icon" style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        height: '100%'
                      }}>
                        <RiLinkedinFill/>
                      </i>
                    </a>
                    <a href="https://www.upwork.com/freelancers/anasraza57/" target="_blank" rel="noreferrer">
                      <i className="icon" style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        height: '100%'
                      }}>
                        <SiUpwork/>
                      </i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* Services */}
            <div className="content services">
              <div className="row">
                <div className="col col-m-12 col-t-12 col-d-12 col-d-lg-12">
                  {/* <!-- title --> */}
                  <div className="title">
                    <span>Our</span> Services
                  </div>
                </div>
              </div>

              {/* <!-- service items --> */}
              <div className="row service-items">
                {/* <!-- service item --> */}
                {serviceItemData.map(({ icon_url, title, text }, index) => {
                  return (
                    <div
                      className="col col-m-12 col-t-6 col-d-6 col-d-lg-6"
                      key={index}
                    >
                      <div className="service-item card-box">
                        <div className="icon">
                          <i className={icon_url}></i>
                        </div>
                        <div className="name">{title}</div>
                        <p>{text}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* // Price Tables */}
            <div className="content pricing">
              <div className="row">
                <div className="col col-m-12 col-t-12 col-d-12 col-d-lg-12">
                  {/* <!-- title --> */}
                  <div className="title">
                    <span>Pricing</span> Tables
                  </div>
                </div>
              </div>

              {/* <!-- pricing items --> */}
              <div className="row pricing-items">
                {/* <!-- pricing item --> */}
                <div className="col col-m-12 col-t-6 col-d-6 col-d-lg-6">
                  <div className="pricing-item card-box">
                    <div className="icon">
                      <i className="la la-tachometer"></i>
                    </div>
                    <div className="name">Basic</div>
                    <div className="amount">
                      <span className="dollar">$</span>
                      <span className="number">30</span>
                      <span className="period">hour</span>
                    </div>
                    <div className="feature-list">
                      <ul>
                        <li>Web Development</li>
                      </ul>
                    </div>
                    <div className="lnks">
                      <a href="#buy" className="lnk">
                        Buy <span>Basic</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* // <!--	Fun Fact --> */}
            <div className="content fuct">
              <div className="row">
                <div className="col col-m-12 col-t-12 col-d-12 col-d-lg-12">
                  {/* <!-- title --> */}
                  <div className="title">
                    <span>Fun</span> Facts
                  </div>
                </div>
              </div>

              {/* <!-- fuct items --> */}
              <div className="row fuct-items">
                {/* <!-- fuct item --> */}
                {FactsData.map(({ icon_url, title, icon }, index) => {
                  return (
                    <div
                      className="col col-m-6 col-t-6 col-d-3 col-d-lg-3"
                      key={index}
                    >
                      <div className="fuct-item card-box">
                        <div className="icon">
                          {icon_url ?
                            <i className={icon_url}></i> :
                            icon
                          }
                        </div>
                        <div className="name">{title}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
